<template>
    <div>
        <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Messages Filter</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="$emit('close')">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card tile>
            <v-card-text>
                <v-select
                    prepend-icon="mdi-message-alert"
                    v-model="filterData.status"
                    clearable
                    color="primary"
                    solo
                    :items="statuses"
                    item-text="text"
                    item-value="value"
                    hint="Filter by message status"
                    persistent-hint
                    placeholder="Status"
                ></v-select>
                <v-select
                    prepend-icon="mdi-calendar"
                    solo
                    v-model="filterData.daterange"
                    :items="ranges"
                    hint="Filter by date range"
                    persistent-hint
                    placeholder="Date range"
                ></v-select>
                <v-text-field
                    prepend-icon="mdi-account-search"
                    solo
                    v-model="filterData.order_id"
                    hint="Filter by order id"
                    persistent-hint
                    placeholder="Order ID"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.stop="applyFilter">
                    <v-icon class="mr-2">mdi-check-circle</v-icon>Apply
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "messages-filter",
    components: {},
    props: {
        filter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            statuses: [
                {
                    text: "failed",
                    value: "error",
                },
                {
                    text: "sent",
                    value: "sent",
                }
            ],
            ranges: [
                {
                    text: "Last 7 days",
                    value: "7",
                },
                {
                    text: "Last 14 days",
                    value: "14",
                },
                {
                    text: "Last 30 days",
                    value: "30",
                },
                {
                    text: "Last 60 days",
                    value: "60",
                },
                {
                    text: "Last 90 days",
                    value: "90",
                },
            ],
            filterData: { ...this.filter },
        };
    },
    created() {},
    methods: {
        applyFilter() {
            this.$emit("filter-messages", this.filterData);
            this.$emit("close");
        },
    },
};
</script>

<style scoped>
</style>