var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { color: "primary", dark: "", dense: "" } },
        [
          _c("v-toolbar-title", [_vm._v("Messages Filter")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("v-select", {
                attrs: {
                  "prepend-icon": "mdi-message-alert",
                  clearable: "",
                  color: "primary",
                  solo: "",
                  items: _vm.statuses,
                  "item-text": "text",
                  "item-value": "value",
                  hint: "Filter by message status",
                  "persistent-hint": "",
                  placeholder: "Status"
                },
                model: {
                  value: _vm.filterData.status,
                  callback: function($$v) {
                    _vm.$set(_vm.filterData, "status", $$v)
                  },
                  expression: "filterData.status"
                }
              }),
              _c("v-select", {
                attrs: {
                  "prepend-icon": "mdi-calendar",
                  solo: "",
                  items: _vm.ranges,
                  hint: "Filter by date range",
                  "persistent-hint": "",
                  placeholder: "Date range"
                },
                model: {
                  value: _vm.filterData.daterange,
                  callback: function($$v) {
                    _vm.$set(_vm.filterData, "daterange", $$v)
                  },
                  expression: "filterData.daterange"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  "prepend-icon": "mdi-account-search",
                  solo: "",
                  hint: "Filter by order id",
                  "persistent-hint": "",
                  placeholder: "Order ID"
                },
                model: {
                  value: _vm.filterData.order_id,
                  callback: function($$v) {
                    _vm.$set(_vm.filterData, "order_id", $$v)
                  },
                  expression: "filterData.order_id"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.applyFilter($event)
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-check-circle")
                  ]),
                  _vm._v("Apply ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }